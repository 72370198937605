export class Project {
    id: number
    naam: string
    datum_aanmaak: string
}

export class Oefening {
    id: number
    project: number
    naam: string
    youtube_id: string
}

export class Oefening_lijn {
    id: number
    oefening: number
    project: number
    label: string
    start: number
    einde: number
    is_loop: number = 0
    num_loop: number = 0
    at_einde: string = At_Einde.PAUSE
}

export class At_Einde{
    static readonly PAUSE: string = 'pause'
    static readonly CONTINUE: string = 'continue'

    static readonly TYPES: string[] = [At_Einde.PAUSE, At_Einde.CONTINUE]

    static readonly SEMANTIC_OPTIONS: object[] = At_Einde.TYPES.map(type => (
        {key: type,
            text: type,
            value: type}))
}
