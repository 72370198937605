import React, {useState, useEffect} from 'react'
import './App.css';
import YouTube from 'react-youtube'
import {Project} from "./class/project";
import {ProjectService} from "./service/ProjectService";
import {OefeningService} from "./service/OefeningService";
import ProjectView from "./view/ProjectView";
import MainContext from "./context/MainContext";


const App = () => {

    const [projectService] = useState(new ProjectService())
    const [oefeningService] = useState(new OefeningService())

    const [youtubeOptions] = useState({
        height: 780/2,
        width: 1080/2,
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
            controls: 1,
            modestbranding: 1,
            autohide: 1
        }})

    return (
        <MainContext.Provider value={{projectService, oefeningService, youtubeOptions}}>
            <ProjectView/>
        </MainContext.Provider>
    );
}

export default App;
