import React, {useState, useEffect, useContext, useRef} from 'react'
import PropTypes from 'prop-types'
import MainContext from "../../context/MainContext";
import YouTube from 'react-youtube'
import {Button, Header, Segment} from "semantic-ui-react";
import OefeningLijnRenderer from "../oefeningLijnen/OefeningLijnRenderer";
import {At_Einde, Oefening_lijn} from "../../class/project";
import {orderBy} from "lodash"

const BASE_WIDTH = 540
const PLAY_SIZE_FACTOR = 3

function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

const OefeningPlayer = ({oefening}) => {

    const [player, setPlayer] = useState()
    const [duration, setDuration] = useState()
    const [playState, setPlayState] = useState('pause')
    const [displaySize, setDisplaySize] = useState()

    const [oefeningLijnen, setOefeningLijnen] = useState()
    const [activeLijn, setActiveLijn] = useState()
    const [activeLoop, setActiveLoop] = useState()

    const [currentPlayTime, setCurrentPlayTime] = useState(0)

    const mainContext = useContext(MainContext)

    useInterval(() => {
        // Your custom logic here
        handleInterval()
    }, 1000);

    //EVENT LISTENER
    useEffect(() => {
        if (!player) return

        player.addEventListener('onStateChange', onPlayerStateChange)
        return () => {
            player.removeEventListener('onStateChange', onPlayerStateChange)
        }
    }, [player])

//INTERVAL
    /*useEffect(() => {
        const tm = setInterval(() => handleInterval(), 1000)
        return () => clearInterval(tm)
    }, [playState, activeLijn])*/

    //OEFENINGLIJNEN
    useEffect(() => {
        mainContext.oefeningService.getOefeningLijnen(oefening).then(res => {
            const ol = orderBy(res, 'start')
            setOefeningLijnen(ol)
        })
    }, [oefening])


    const onPlayerStateChange = (data) => {

        const PS = window.YT.PlayerState
        const ratioArray = oefening.ratio.split(':')
        const heightFactor = ratioArray[0] / ratioArray[1]
        const displaySize = {w: BASE_WIDTH, h: BASE_WIDTH / heightFactor}

        const realTime = Math.round(player.getCurrentTime())

        switch (data.data) {
            case PS.ENDED:
                console.log('ENDED')
                setPlayState('pause')
                break
            case PS.PLAYING:
                console.log('PLAYING', oefening)
                player.setSize(displaySize.w * PLAY_SIZE_FACTOR, displaySize.h * PLAY_SIZE_FACTOR)
                if (currentPlayTime !== realTime) setCurrentPlayTime(realTime)
                setPlayState('play')
                break
            case PS.PAUSED:
                console.log('PAUSED')
                player.setSize(displaySize.w, displaySize.h)
                setPlayState('pause')
                break
            case PS.BUFFERING:
                console.log('BUFFERING')
                break
            case PS.CUED:
                console.log('CUED')
                break
        }

    }

    const onReady = (event) => {
        const p = event.target
        setPlayer(p)

        p.hideVideoInfo()
        const d = p.getDuration()
        setDuration(d)

        //SIZE
    }

    const onTogglePlay = () => {
        if (!activeLijn) return

        if (playState === 'pause') {
            setPlayState('play')
            player.playVideo()

        } else {
            setPlayState('pause')
            player.pauseVideo()
        }
    }

    const handleInterval = (event) => {

        if (!player || playState === 'pause' || !activeLijn) return

        let ct = Math.round(player.getCurrentTime())
        if (ct > activeLijn.einde) {
            console.log('LOOPING')
            if (activeLijn.is_loop === 1) {
                let doLoop = activeLijn.num_loop === 0 || activeLoop < activeLijn.num_loop
                console.log('DOLOOP', doLoop, activeLoop)
                if (doLoop) {
                    setActiveLoop(activeLoop+1)
                    ct = activeLijn.start
                    player.seekTo(ct)
                } else {
                    player.pauseVideo()
                    checkAtEinde(activeLijn)
                }
            } else {
                player.pauseVideo()
                checkAtEinde(activeLijn)
            }
        }

        setCurrentPlayTime(ct)

    }

    const checkAtEinde = (oefeningLijn) => {
        if (oefeningLijn.at_einde === At_Einde.CONTINUE){
            const ali = oefeningLijnen.indexOf(oefeningLijn)
            if (ali < oefeningLijnen.length){
                const nextLijn = oefeningLijnen[ali+1]
                console.log('NEXT', nextLijn)
                if (nextLijn) onOefeningLijnClick(nextLijn)
            }
        }
    }

    const onOefeningLijnClick = (lijn) => {
        if (lijn === activeLijn) {
            player.pauseVideo()
            return
        }

        setActiveLijn(lijn)
        setActiveLoop(1)
        player.seekTo(lijn.start)
        player.playVideo()
        setCurrentPlayTime(lijn.start)
    }

    const addOefeningLijn = () => {
        player.pauseVideo()
        setActiveLijn(null)

        const label = window.prompt('Label van deze oefening lijn', '')
        if (label) {
            const ol = new Oefening_lijn()
            ol.oefening = oefening.id
            ol.project = oefening.project
            ol.start = currentPlayTime
            ol.einde = Math.round(player.getDuration())
            ol.label = label
            //ol.isNew = true
            mainContext.oefeningService.createOefeningLijn(ol).then(res => {
                setOefeningLijnen(orderBy(res.oefeningLijnen, 'start'))
                setActiveLijn(res.oefeningLijn)
                player.seekTo(currentPlayTime)
            })
        }
    }

    const deleteOefeningLijn = (oefeningLijn) => {

        player.pauseVideo()
        setActiveLijn(null)

        mainContext.oefeningService.deleteOefeningLijn(oefeningLijn).then(res => {
            console.log('DELETED', res)
            setOefeningLijnen(orderBy(res.oefeningLijnen, 'start'))
        })
    }

    const updateOefeningLijn = (oefeningLijn) => {
        console.log('UOPDATING')
        mainContext.oefeningService.updateOefeningLijn(oefeningLijn).then(res => {
            const oln = oefeningLijnen.map(ol => {
                if (ol.id === oefeningLijn.id) return oefeningLijn
                return ol
            })
            setOefeningLijnen(orderBy(oln, 'start'))
            setActiveLijn({...oefeningLijn})
        })
    }

    const onSetTime = (prop) => {
        console.log('SET TIME', prop, currentPlayTime)

        activeLijn[prop] = currentPlayTime
        if (activeLijn.start >= activeLijn.einde) activeLijn.einde = activeLijn.start + 1

        mainContext.oefeningService.updateOefeningLijn(activeLijn).then(res => {
            console.log('RES', res)
            // setActiveLijn({...activeLijn})
            setOefeningLijnen([...orderBy(oefeningLijnen, 'start')])
        })
    }

    const onToggleLoop = () => {
        activeLijn.is_loop = activeLijn.is_loop === 0 ? 1 : 0
        mainContext.oefeningService.updateOefeningLijn(activeLijn).then(res => {
            setOefeningLijnen([...orderBy(oefeningLijnen, 'start')])
        })
    }

    return (
        <div>

            <Segment>
                {activeLijn && <Header>{activeLijn.label}</Header>}
                {activeLijn && activeLijn.is_loop && activeLijn.num_loop > 0 && <p>Loop {activeLoop}/{activeLijn.num_loop}</p>}
            </Segment>
            <YouTube videoId={oefening.youtube_id} opts={mainContext.youtubeOptions} onReady={onReady}/>

            {/*<Segment>
                {playState === 'pause' && <Button icon='play' color='green' onClick={onTogglePlay}/>}
                {playState === 'play' && <Button icon='pause' color='green' onClick={onTogglePlay}/>}
                {activeLijn && <Button color='teal' onClick={() => onSetTime('start')}>SET Start</Button>}
                {activeLijn && <Button color='teal' onClick={() => onSetTime('einde')}>SET Einde</Button>}
            </Segment>*/}
            {/*<OefeningLijnViewer oefeningLijnen={oefeningLijnen}/>*/}

            {oefeningLijnen && player && oefeningLijnen.map(ol => {
                return <OefeningLijnRenderer key={ol.id}
                                             oefeningLijn={ol}
                                             active={activeLijn && ol.id === activeLijn.id}
                                             playState={playState}
                                             currentPlayTime={currentPlayTime}
                                             onOefeningLijnClick={onOefeningLijnClick}
                                             onTogglePlay={onTogglePlay}
                                             onSetTime={onSetTime}
                                             onToggleLoop={onToggleLoop}
                                             onDelete={deleteOefeningLijn}
                                             onUpdate={updateOefeningLijn}/>
            })}

            <Button onClick={addOefeningLijn}>Voeg lijn toe</Button>
        </div>
    )
}

export default OefeningPlayer

OefeningPlayer.propTypes = {}

OefeningPlayer.defaultProps = {}
