import React, {useState, useEffect, useRef, Fragment} from 'react'
import PropTypes from 'prop-types'
import Parser from 'html-react-parser'
import {Header, Input, TextArea, Button} from "semantic-ui-react";

const EditableField = ({provider, prop, onSave, header, singleLine, label, prefix, suffix, editable}) => {

    const [fieldValue, setFieldValue] = useState('')
    const [code, setCode] = useState()
    const [isEditing, setIsEditing] = useState(false)

    const inputRef = useRef()

    useEffect(() => {
        if(prop) setFieldValue(provider[prop])
        setCode(prefix ? prefix + '-' + provider.id.toString().padStart(3, '0') + ' ' : '')
    }, [provider, prefix])

    useEffect(() => {
        if (inputRef.current) inputRef.current.focus()
    }, [inputRef.current])

    const onCancelHandler = () => {
        setFieldValue(provider[prop])
        setIsEditing(false)
    }

    const onSaveHandler = () => {
        onSave && onSave(prop, fieldValue)
        setIsEditing(false)
    }

    const onFieldValueChange = (event, {value}) => {
        setFieldValue(value)
    }

    const keyDownHandler = (event) => {
        if (event.keyCode === 13){
            onSaveHandler()
        }
    }

    const onDoubleClick = (e) => {
        if (!editable) return
        if (!isEditing) setIsEditing(true)
        setTimeout(focusOnInput, 200)
    }

    const focusOnInput = () => {
        if (inputRef.current) inputRef.current.focus()
    }

    return (
        <div style={{flexGrow: 1}} onDoubleClick={onDoubleClick}>

            {label && <div style={{marginBottom: '5px'}}><strong>{label}</strong></div>}

            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>

                {header && !isEditing && <Header style={{margin: 0}}>{code}{fieldValue}{suffix && suffix(provider)}</Header>}
                {!header && !isEditing && <p>{Parser(fieldValue.toString().replaceAll('\n', '<br />'))}{suffix && suffix(provider)}</p>}

                {isEditing && singleLine && <Input ref={inputRef} style={{flexGrow: '1'}} value={fieldValue} onChange={onFieldValueChange} onKeyDown={keyDownHandler}/>}
                {isEditing && !singleLine && <TextArea style={{flexGrow: '1'}} value={fieldValue} onChange={onFieldValueChange}/>}

                {editable && <div style={{marginLeft: '20px'}}>

                    {!isEditing && <Button size='mini' circular icon='pencil' onClick={() => setIsEditing(true)}/>}
                    {isEditing && <Fragment>
                        <Button size='mini' color='red' circular icon='cancel' onClick={onCancelHandler}/>
                        <Button size='mini' color='green' circular icon='check' onClick={onSaveHandler}/>
                    </Fragment>}
                </div>}
            </div>
        </div>
    )
}

export default EditableField

EditableField.propTypes = {
    provider: PropTypes.object.isRequired,
    prop: PropTypes.string,
    header: PropTypes.bool,
    singleLine: PropTypes.bool,
    label: PropTypes.string,
    prefix: PropTypes.string,
    suffix: PropTypes.func,
    editable: PropTypes.bool.isRequired,
}

EditableField.defaultProps = {
    header: false,
    singleLine: true,
    editable: true
}
