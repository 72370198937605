import React, {useState, useEffect, useContext} from 'react'
import MainContext from "../context/MainContext";
import ProjectList from "../component/project/ProjectList";
import ProjectDetail from "../component/project/ProjectDetail";
import {Project} from "../class/project";

const ProjectView = (props) => {

    const [projects, setProjects] = useState()
    const [selectedProject, setSelectedProject] = useState()
    const [view, setView] = useState('list')

    const mainContext = useContext(MainContext)

    useEffect(() => {
        mainContext.projectService.getProjecten().then(res => {
            console.log('PROJ', res)
            setProjects(res)
        })
    }, [mainContext.projectService])

    const onProjectSelect = (project) => {
        setSelectedProject(project)
        setView('detail')
    }

    const onCloseProject = () => {
        setSelectedProject(null)
        setView('list')
    }

    const onCreateProject = () => {
        const np = new Project()
        np.naam = 'Nieuw project'
        mainContext.projectService.createProject(np).then(res => {
            setProjects(res.projecten)
            setSelectedProject(res.project)
            setView('detail')
        })
    }

    const onDeleteProject = (project) => {
        const warn = window.confirm('Ben je zeker dat je dit project wil verwijderen?')
        if (warn) {
            mainContext.projectService.deleteProject(project).then(res => {
                setProjects(res.projecten)
                setSelectedProject(null)
                setView('list')
            })
        }
    }

    const onUpdateProject = (prop, fieldValue) => {
        selectedProject[prop] = fieldValue
        mainContext.projectService.updateProject(selectedProject).then(res => {

        })
    }

    return (
        <div>
            {view === 'list' && projects && <ProjectList projects={projects} onProjectSelect={onProjectSelect} onCreateProject={onCreateProject}/>}
            {view === 'detail' && selectedProject && <ProjectDetail project={selectedProject}
                                                                    onClose={onCloseProject}
                                                                    onDelete={onDeleteProject}
                                                                    onUpdate={onUpdateProject}/>}
        </div>
    )
}

export default ProjectView

ProjectView.propTypes = {}

ProjectView.defaultProps = {}
