import React, {useState, useEffect, useContext} from 'react'
import PropTypes from 'prop-types'

import {Button, Form, Input} from "semantic-ui-react";

const OefeningProps = ({oefening, onSave}) => {

    const [localOefening, setLocalOefening] = useState({...oefening})

    const onPropChange = (event, {name, value}) => {
       setLocalOefening({...localOefening, [name]: value})
    }

    const onLocalSave = () => {
        onSave(localOefening)
    }

    return (
        <Form>

            <Form.Input fluid name='youtube_id' value={localOefening.youtube_id} label='Youtube id' placeholder='Enter youtube id' onChange={onPropChange}/>
            <Form.Input fluid name='ratio' value={localOefening.ratio} label='Ratio' placeholder='Enter ratio' onChange={onPropChange}/>
            <Form.Button onClick={onLocalSave}>Bewaar</Form.Button>
        </Form>
    )
}

export default OefeningProps

OefeningProps.propTypes = {}

OefeningProps.defaultProps = {}
