import React, {useState, useEffect, useContext} from 'react'
import PropTypes from 'prop-types'
import {Button, Header, List, Segment} from "semantic-ui-react";

const OefeningenList = ({project, oefeningen, onOefeningSelect, onCreateOefening}) => {

    useEffect(() => {

    }, [])

    if (!oefeningen) return null

    return (
        <div>
            <Header as='h1' attached textAlign='center' inverted>Oefeningen</Header>

            <Segment>
                <Button size='tiny' onClick={onCreateOefening}>Nieuwe oefening</Button>
            </Segment>
            <Segment inverted>
                <List divided inverted relaxed>
                    {oefeningen.map(oefening => {
                        return <List.Item key={oefening.id} style={{cursor: 'pointer'}} onClick={() => onOefeningSelect(oefening)}>
                            <List.Content>
                                <List.Header>{oefening.naam}</List.Header>
                            </List.Content>
                        </List.Item>
                    })}


                </List>
            </Segment>
        </div>
    )
}

export default  OefeningenList

OefeningenList.propTypes = {

}

OefeningenList.defaultProps = {

}
