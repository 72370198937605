import React, {useState, useEffect, useContext} from 'react'
import PropTypes from 'prop-types'
import {Segment, Button} from "semantic-ui-react";
import MainContext from "../../context/MainContext";

import EditableField from "../EditableField";
import OefeningenView from "../../view/OefeningenView";
import OefeningPlayer from "./OefeningPlayer";
import OefeningProps from "./OefeningProps";

const OefeningDetail = ({oefening, onClose, onDelete, onUpdateProp, onUpdateOefening}) => {

    const [view, setView] = useState('player')
    const mainContext = useContext(MainContext)

    const toggleView = () => {
        if (view === 'player'){
            setView('edit')
        } else {
            setView('player')
        }
    }

    const onOefeningUpdate = (oef) => {
        setView('player')
        onUpdateOefening(oef)
    }

    return (
        <div>
            <Segment style={{display: 'flex', justifyContent: 'left'}}>
                <Button size='tiny' onClick={onClose}>Sluit</Button>
                {view === 'player' && <Button size='tiny' onClick={toggleView}>Edit oefening</Button>}
                <EditableField provider={oefening} prop='naam' header onSave={onUpdateProp}/>
                <Button size='tiny' icon='trash' color='red' onClick={() => onDelete(oefening)}/>
            </Segment>

            {view === 'player' && <OefeningPlayer oefening={oefening}/>}
            {view === 'edit' && <OefeningProps oefening={oefening} onSave={onOefeningUpdate}/>}
        </div>
    )
}

export default  OefeningDetail

OefeningDetail.propTypes = {

}

OefeningDetail.defaultProps = {

}
