import React, {useState, useEffect, useContext} from 'react'
import PropTypes from 'prop-types'
import {Button, Segment, Label, Modal, Header, Form} from "semantic-ui-react";
import EditableField from "../EditableField";
import {At_Einde} from "../../class/project";

const OefeningLijnRenderer = ({oefeningLijn, active, playState, onOefeningLijnClick, currentPlayTime, onTogglePlay, onSetTime, onToggleLoop, onDelete, onUpdate}) => {

    const [editModalOpen, setEditModalOpen] = useState(false)
    const [localOefeningLijn, setLocalOefeningLijn] = useState()

    useEffect(() => {
        oefeningLijn.isNew = false
        setLocalOefeningLijn({...oefeningLijn})
    }, [oefeningLijn])

    const onUpdateProp = (event, {name, value}) => {
        setLocalOefeningLijn({...localOefeningLijn, [name]: value})
    }

    const onUpdateLoop = (event, {checked}) => {
        setLocalOefeningLijn({...localOefeningLijn, is_loop: checked ? 1 : 0})
    }

    const onSaveLocal = () => {
        setEditModalOpen(false)
        onUpdate(localOefeningLijn)
    }

    const onDeleteLocal = () => {
      const w = window.confirm('Ben je zeker dat je deze oefening lijn wil verwijderen?')

      if (w){
          setEditModalOpen(false)
          onDelete(oefeningLijn)
      }
    }

    const onModalOpen = () => {
        setLocalOefeningLijn({...oefeningLijn})
        setEditModalOpen(true)
    }

    if (!localOefeningLijn) return null

    return (
        <Segment style={{display: 'flex', alignItems: 'center'}} color='grey' inverted={active} onClick={() => !editModalOpen && onOefeningLijnClick(oefeningLijn)}>

            {active && playState === 'pause' && <Button icon='play' size='tiny' color='green'/>}
            {active && playState === 'play' && <Button icon='pause' color='green'/>}

            <Label color='teal' style={{width: '200px'}}>{oefeningLijn.label}</Label>

            <Label>Start: {oefeningLijn.start}</Label>
            {active && <Label as='a' color='green' onClick={() => onSetTime('start')}>SET</Label>}

            <Label>Einde: {oefeningLijn.einde}</Label>
            {active && <Label as='a' color='green' onClick={() => onSetTime('einde')}>SET</Label>}

            {active && <Label color='blue'>Current: {currentPlayTime}</Label>}
            <Label>Loop: {oefeningLijn.is_loop}</Label>
            {active && <Label as='a' color='green' onClick={onToggleLoop}>TOGGLE</Label>}
            <Label># loops: {oefeningLijn.num_loop}</Label>
            <Label>At einde: {oefeningLijn.at_einde}</Label>

            {active && <Button icon='pencil' size='tiny' color='red' onClick={onModalOpen}/>}

            {active && <Modal
                onClose={() => setEditModalOpen(false)}
                open={editModalOpen}
            >
                <Modal.Header>Edit oefening lijn</Modal.Header>
                <Modal.Content image>
                    <Form>
                        <Form.Input label='Label' value={localOefeningLijn.label} name='label' onChange={onUpdateProp}/>
                        <Form.Input label='Start' value={localOefeningLijn.start} name='start' onChange={onUpdateProp}/>
                        <Form.Input label='Einde' value={localOefeningLijn.einde} name='einde' onChange={onUpdateProp}/>
                        <Form.Checkbox label='Loop' checked={localOefeningLijn.is_loop === 1} onChange={onUpdateLoop}/>
                        <Form.Input label='Aantal loops' value={localOefeningLijn.num_loop} name='num_loop' onChange={onUpdateProp}/>
                        <Form.Select label='Na einde' options={At_Einde.SEMANTIC_OPTIONS} value={localOefeningLijn.at_einde} name='at_einde' onChange={onUpdateProp}/>
                    </Form>
                </Modal.Content>

                <Modal.Actions>
                    <Button onClick={() => setEditModalOpen(false)}>
                        Annuleer
                    </Button>
                    <Button
                        color='green'
                        onClick={onSaveLocal}
                    >Bewaar</Button>
                    <Button color='red' onClick={onDeleteLocal}>Verwijder deze lijn</Button>
                </Modal.Actions>
            </Modal>}
        </Segment>
    )
}

export default OefeningLijnRenderer

OefeningLijnRenderer.propTypes = {}

OefeningLijnRenderer.defaultProps = {}
