import React, {useState, useEffect, useContext} from 'react'
import MainContext from "../context/MainContext";
import ProjectList from "../component/project/ProjectList";
import ProjectDetail from "../component/project/ProjectDetail";
import {Oefening} from "../class/project";
import OefeningenList from "../component/oefeningen/OefeningenList";
import OefeningDetail from "../component/oefeningen/OefeningDetail";

const OefeningenView = ({project}) => {

    const [oefeningen, setOefeningen] = useState()
    const [selectedOefening, setSelectedOefening] = useState()
    const [view, setView] = useState('list')

    const mainContext = useContext(MainContext)

    useEffect(() => {
        mainContext.oefeningService.getOefeningen(project).then(res => {
            setOefeningen(res)
        })
    }, [project])

    const onOefeningSelect = (oefening) => {
        setSelectedOefening(oefening)
        setView('detail')
    }

    const onCloseOefening = () => {
        setSelectedOefening(null)
        setView('list')
    }

    const onCreateOefening = () => {
        const no = new Oefening()
        no.project = project.id
        no.naam = 'Nieuwe oefening'
        mainContext.oefeningService.createOefening(no).then(res => {
            console.log('NO', res)
            setOefeningen(res.oefeningen)
            setSelectedOefening(res.oefening)
            setView('detail')
        })
    }

    const onDeleteOefening = (oefening) => {
        const warn = window.confirm('Ben je zeker dat je deze oefening wil verwijderen?')
        if (warn) {
            mainContext.oefeningService.deleteOefening(oefening).then(res => {
                setOefeningen(res.oefeningen)
                setSelectedOefening(null)
                setView('list')
            })
        }
    }

    const onUpdateOefeningProp = (prop, fieldValue) => {
        selectedOefening[prop] = fieldValue
        mainContext.oefeningService.updateOefening(selectedOefening).then(res => {

        })
    }

    const onUpdateOefening = (oefening) => {
        mainContext.oefeningService.updateOefening(oefening).then(res => {
            setSelectedOefening({...oefening})
        })
    }

    return (
        <div>
            {view === 'list' && oefeningen && <OefeningenList oefeningen={oefeningen} onOefeningSelect={onOefeningSelect} onCreateOefening={onCreateOefening}/>}
            {view === 'detail' && selectedOefening && <OefeningDetail oefening={selectedOefening}
                                                                      onClose={onCloseOefening}
                                                                      onDelete={onDeleteOefening}
                                                                      onUpdateOefening={onUpdateOefening}
                                                                      onUpdateProp={onUpdateOefeningProp}/>}
        </div>
    )
}

export default OefeningenView
