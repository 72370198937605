import React, {useState, useEffect, useContext} from 'react'
import PropTypes from 'prop-types'
import {Segment, Button} from "semantic-ui-react";
import EditableField from "../EditableField";
import OefeningenView from "../../view/OefeningenView";

const ProjectDetail = ({project, onClose, onDelete, onUpdate}) => {

    return (
        <div>
            <Segment style={{display: 'flex', justifyContent: 'left'}}>
                <Button size='tiny' onClick={onClose}>Sluit</Button>
                <Button size='tiny'>Edit project</Button>
                <EditableField provider={project} prop='naam' header onSave={onUpdate}/>
                <Button size='tiny' icon='trash' color='red' onClick={() => onDelete(project)}/>
            </Segment>

            <OefeningenView project={project}/>
        </div>
    )
}

export default  ProjectDetail

ProjectDetail.propTypes = {

}

ProjectDetail.defaultProps = {

}
